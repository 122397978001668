import { eventQBus } from "@otto-ec/global-resources/event-q-bus";

const ONE_SECOND = 1000;
const ONE_MINUTE = ONE_SECOND * 60;
const ONE_HOUR = ONE_MINUTE * 60;
const ONE_DAY = ONE_HOUR * 24;

export declare type TopicName = `${string}.${string}.${string}`;

export class Countdown {
	constructor(private readonly expiredTopic?: TopicName) {}

	sheetCountdownInit(scarcityDom: HTMLElement) {
		const posixTimestamp = scarcityDom.dataset.activeUntil;
		if (posixTimestamp) {
			const end = new Date(posixTimestamp);

			const needToStartCountdown = this.setCountdownOrExpiryText(new Date(), end, scarcityDom, undefined);
			if (needToStartCountdown) {
				const intervalId = window.setInterval(() => {
					this.setCountdownOrExpiryText(new Date(), end, scarcityDom, intervalId);
				}, 500);
			}
		}
	}

	cinemaCountdownInit(lines: HTMLCollectionOf<HTMLElement>) {
		for (const line of lines) {
			this.countdownInit(line);
		}
	}

	countdownInit(line: HTMLElement) {
		const posixTimestamp = line.dataset.activeUntil;
		if (posixTimestamp) {
			const end = new Date(posixTimestamp);

			const intervalId = window.setInterval(() => {
				this.setCountdownOrExpiryText(new Date(), end, line, intervalId);
			}, 500);
			this.setCountdownOrExpiryText(new Date(), end, line, intervalId);
		}
	}

	initPapercardCountdown(container: HTMLElement, forceInitialize = false) {
		const elements = container.querySelectorAll("oc-paper-card-v1 .benefit-js-countdown") as NodeListOf<HTMLElement>;

		for (const element of elements) {
			if (element.dataset.countdownInitialized === "true" && !forceInitialize) {
				continue;
			}

			this.countdownInit(element);

			element.dataset.countdownInitialized = "true";
		}
	}

	setCountdownOrExpiryText(now: Date, end: Date, subline: HTMLElement, intervalId: number | undefined): boolean {
		const time_range_ms = end.getTime() - now.getTime();
		if (time_range_ms <= ONE_SECOND) {
			window.clearInterval(intervalId);
			window.setTimeout(() => {
				subline.textContent = "Leider abgelaufen";
				if (this.expiredTopic) {
					eventQBus.emit(this.expiredTopic);
				}
			}, ONE_SECOND);
		} else if (time_range_ms < ONE_DAY) {
			const hours = Math.floor((time_range_ms % ONE_DAY) / ONE_HOUR);
			const minutes = Math.floor((time_range_ms % ONE_HOUR) / ONE_MINUTE);
			const seconds = Math.floor((time_range_ms % ONE_MINUTE) / ONE_SECOND);

			const hours_string = hours > 0 ? ` ${hours}h` : "";
			const minutes_string = minutes > 0 ? ` ${minutes}m` : "";
			const seconds_string = seconds > 0 ? ` ${seconds}s` : "";

			subline.textContent = `nur noch${hours_string}${minutes_string}${seconds_string}`;
		} /*                                                                                             */
		return true;
	}
}
